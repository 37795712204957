/*{
    title: '',
    resources: [],
    tags: [],
    deliveries: [
        {
        event: '',
        date: '',
        recording: '',
        }
    ],
    description: `
    `,
};*/

export default [
    {
      title: 'RESTful API Best Practices using ASP.NET Core',
      resources: [
        '[slides and more info](/talks/rest)',
      ],
      tags: ["asp.net", "rest"],
      deliveries: [
        {
          event: 'DevReach',
          date: 'October 2019',
          recording: '',
        },
        {
          event: 'KCDC',
          date: 'August 2017',
          recording: '',
        },
        {
          event: 'Detroit.Code()',
          date: 'July 2017',
          recording: '',
        },
        {
          event: 'Nebraska.Code()',
          date: 'May 2017',
          recording: '',
        },
        {
          event: 'NDC London',
          date: 'January 2017',
          recording: '',
        },
      ],
      description: `Designing and building RESTful APIs isn’t easy. On its surface, it may seem simple – after all, we’re only marshaling JSON back and forth over HTTP right? However, that’s only a small part of the equation. There are many things to keep in mind while building the systems that act as the key to your system.

In this session, we’ll delve into several best practices to keep in mind when designing your RESTful API. We’ll discuss authentication, versioning, controller/model design, and testability. We’ll also explore the do’s and don’t’s of RESTful API management so that you make sure your APIs are simple, consistent, and easy-to-use. Finally, we’ll discuss the importance of documentation and change management.

The session will show examples using ASP.NET Web API and C#. However, this session will benefit anyone who is or might be working on a RESTful API.
      `,
      youtubeLink: 'x0yNKU-tz1Y'
    },
    {
      title: 'An Opinionated, Maintainable REST API Architecture for ASP.NET Core',
      resources: [
        '[slides and more info](/talks/rest)',
      ],
      tags: ["asp.net", "rest"],
      deliveries: [
        {
          event: 'DevConf Poland',
          date: 'September 2017',
          recording: '',
        },
        {
          event: 'NDC Oslo',
          date: 'June 2017',
          recording: '',
        },
      ],
      description: `Writing a brand-new RESTful API using ASP.NET has never been easier, or so you’ve been led to believe. Create a project, write a few models, and scaffold – instant API for your website! While convenient, this leads to a lot of pain as your API scales. Testability and maintainability become more and more difficult as your codebase continues to expand. Eventually, you’re left with a huge mess of code and zero separation of concerns.

In this session, Spencer will provide a highly-opinionated and easy-to-learn pattern for creating scalable, maintainable APIs powered by ASP.NET Core. Attendees will learn about dependency injection, validating requests, executing requests via services, error handling, and versioning strategies to make sure your API lasts for the long haul.`,
      youtubeLink: 'CH9VEeV-zok'
    },
    {
      title: 'Using C# Expression Trees in the Real World',
      resources: [
        '[slides and examples](https://github.com/schneidenbach/CSharpExpressionTreesInTheRealWorld)'
      ],
      tags: ["c#", "immutability"],
      deliveries: [
        {
          event: 'NDC Minnesota',
          date: 'May 2019',
          recording: '',
        },
        {
          event: 'NDC Porto',
          date: 'February 2019',
          recording: '',
        }
      ],
      description: `Entity Framework is the de facto data access standard in .NET, and for good reason – its simplicity and power enables anyone to quickly write and execute strongly-typed queries using LINQ. Have you ever wondered how Entity Framework translates even the most complicated of LINQ queries to SQL? The answer is simple – expression trees.

Used by many libraries we know and love (including EF, AutoMapper, and NHibernate), expression trees provide a lot of power in an easy-to-use API surface. In this session, Spencer will unravel the mysteries of expression trees. Attendees will learn what expression trees are, how to create them, and how libraries like Entity Framework and AutoMapper use them to perform routine tasks.`,
      youtubeLink: 'Ptnxc6tVIPE'
    },
    {
      title: 'Versioning and Maintaining Your REST API in ASP.NET Core',
      resources: [
        '[slides and more info](/talks/rest)',
      ],
      tags: ["asp.net", "rest"],
      deliveries: [
        {
          event: 'KCDC',
          date: 'July 2018',
          recording: '',
        }
      ],
      description: `When tasked with making a REST API for your system, you'll inevitably have to think about versioning. One of the most difficult parts of API maintenance, versioning is an essential conversation to have about the future of your API, even before you hit File -> New Project. Do you version in the URL (v1/api/whatever)? Do you use header versioning? Should I update my production API in place?

In this session, Spencer will provide several strategies and real world examples of API versioning done right... and wrong. We'll discuss versioning strategies such as header versioning and URL versioning, as well as best practices on how to intelligently version your REST API without tearing your hair out... or making your consumers tear their hair out.
      `,
      youtubeLink: ''
    },
    {
      title: 'Using Immutable Data Structures in C# and .NET',
      resources: [
        '[slides](https://github.com/schneidenbach/ImmutableDataStructuresInCSharpAndDotNet)',
      ],
      tags: ["asp.net", "rest"],
      deliveries: [
        {
          event: 'DevConf Poland',
          date: 'September 2018',
          recording: '',
        }
      ],
      description: `Immutable objects - aka objects whose properties and fields can't change after instantiation - are one of the fundamental pillars of functional programming. Yet, it also has other great uses in classically object-oriented languages such as C#. Immutability can help make your code cleaner, easier to reason about, and reduce runtime errors. But how can you do that without making it feel like you're fighting against the typically-mutable C# and the entire .NET Framework?

In this session, Spencer will discuss immutability and how you can apply it in your day-to-day as a .NET/C# developer. We'll go over immutable objects, immutable collections, and how you can refactor your code to successfully apply immutability to your exisitng codebase. Spencer will also discuss the implications of introducing immutability to your code, both positive and negative.      
      `,
      youtubeLink: '1eIoQbVd8fQ',
    },
    {
      title: 'Your JavaScript Needs Types',
      resources: [
        '[slides](https://github.com/schneidenbach/YourJavaScriptNeedsTypes)'
      ],
      tags: ['javascript', 'typescript'],
      deliveries: [
        {
          event: 'KCDC',
          date: 'July 2018',
          recording: '',
        },
        {
          event: 'DevUp',
          date: 'October 2017',
          recording: '',
        },
        {
          event: 'DevConf Poland',
          date: 'September 2017',
          recording: '',
        }
      ],
      description: `What’s one thing Slack, Facebook, and Google have in common? They’ve all embraced types in their JavaScript! Typed JavaScript is meeting lots of resistance from folks who don’t believe their JavaScript needs it, but many projects are transitioning to type systems such as TypeScript or Flow for better long-term maintainability and bug reduction.

In this session, Spencer will break down why types in your JavaScript will make you a better JS developer. We’ll explore the benefits brought on by typed JavaScript, such as better tooling and ease of refactoring. Finally, we’ll discuss how you can introduce typing to your JavaScript in projects small to large, new and old.      
      `,
      youtubeLink: 'xVo0M_vVyxw',
    },
    {
      title: 'Deconstructing TypeScript\'s Type System',
      resources: [
        '[slides](https://github.com/schneidenbach/TypeScriptTypeSystem)'
      ],
      tags: ['javascript', 'typescript'],
      deliveries: [
        {
          event: 'NDC Oslo',
          date: 'June 2018',
          recording: '',
        }
      ],
      description: `TypeScript isn’t just a strongly-typed replacement for JavaScript – it’s a full language with lots of modern features, including a cutting-edge type system. This awesome type system is often overlooked in favor of other features such as decorators and async/await, but is one of the best reasons to use TypeScript.

In this session, Spencer will discuss the advanced type features of TypeScript, including intersection types, type guards, nullable types, type aliases, string literals, and discriminated unions. We’ll also discuss ways to take full advantage of TypeScript’s compile-time checking to ensure your code is clean and safe.`,
      youtubeLink: 'fRgFVNhSJEc',
    },
    {
      title: 'Three Rewrites, One Startup - An Architect’s Journey',
      resources: [],
      tags: ['architecture'],
      deliveries: [
        {
          event: 'NDC Oslo',
          date: 'June 2019',
          recording: '',
        }
      ],
      description: `Picture this: you're a senior engineer at your company who, one day, is thrust into the architect role. Suddenly, you're having to think about things that you've never thought about - what's the difference between a microservice and a service bus? How do I design a scalable system for tomorrow without overengineering today?

Better yet, if you're at a startup, do all that - and still be responsible for code reviews, development, and coaching of junior devs. How can a fledgling architect possibly "do it all" - and more importantly, do it well?
      
Luckily, someone has gone down that path (and is still going)... and he's ready to share his story.
      
Attend Spencer's talk and hear about how he, as a senior engineer, had to grow into the architect role. We'll discuss the problems faced, the critical "decision points" he made from an architecture standpoint, the results of those choices, and how he helped guide the business through three architectural rewrites - all in a span of four years!      
      `,
      youtubeLink: 'ZwvpW-ldbOM',
    },
]